import {
    enableBodyScroll,
    disableBodyScroll
} from '@/helpers/bodyScrollLockWrapper';

function initZoomModals() {
    const modal = document?.querySelector('[data-modal-zoom]');
    if (!modal) {
        return;
    }

    const modalInner = modal?.querySelector('[data-modal-zoom-inner]');
    if (!modalInner) {
        return;
    }

    const zoomContainer = modal?.querySelector('[data-modal-zoom-container]');
    if (!zoomContainer) {
        return;
    }

    let position_start_x = 0;
    let position_start_y = 0;

    function handleZoomIn(event) {
        zoomContainer?.classList?.add('zooming');

        let position_x = event?.changedTouches ? event?.changedTouches[0]?.screenX : event?.screenX;
        let position_y = event?.changedTouches ? event?.changedTouches[0]?.screenY : event?.screenY;

        if (!![
            'mousedown',
            'touchstart',
        ]?.includes(event?.type)) {
            position_start_x = position_x;
            position_start_y = position_y;
        }

        position_x = position_start_x - position_x;
        position_y = position_start_y - position_y;

        zoomContainer.style.transform = `translate(${ position_x }px, ${ position_y }px) scale(4)`;
    }

    function handleZoomOut() {
        zoomContainer?.classList?.remove('zooming');

        zoomContainer.style.transform = `translate(0, 0) scale(1)`;
    }

    function setUpZoomListeners() {
        zoomContainer?.addEventListener('mousedown', handleZoomIn);
        zoomContainer?.addEventListener('mousemove', handleZoomIn);
        zoomContainer?.addEventListener('touchstart', handleZoomIn);
        zoomContainer?.addEventListener('touchmove', handleZoomIn);
        zoomContainer?.addEventListener('mouseup', handleZoomOut);
        zoomContainer?.addEventListener('touchend', handleZoomOut);
        zoomContainer?.addEventListener('touchcancel', handleZoomOut);
    }

    function openZoomModal(data = {}) {
        if (!Object?.keys(data)?.length) {
            return;
        }

        if (!data?.image_url) {
            return;
        }

        if (data.breakpoint_max && !Number.isNaN(data.breakpoint_max) && window.innerWidth > Number(data.breakpoint_max)) {
            return;
        }

        zoomContainer.innerHTML = `<img 
            src="${ data?.image_url }" 
            alt="Image Zoom"
            class="absolute-img"
            data-image-zoom-image
        >`;

        const image = modal?.querySelector('[data-image-zoom-image]');
        if (!image?.complete) {
            zoomContainer?.classList?.add('placeholder-animation');

            image?.addEventListener('load', () => {
                zoomContainer?.classList?.remove('placeholder-animation');
            });
        }

        modal?.classList?.remove('hide');

        disableBodyScroll(modalInner);
    }

    function closeZoomModal() {
        modal?.classList?.add('hide');

        zoomContainer.innerHTML = '';

        enableBodyScroll(modalInner);
    }

    function setUpOpeners() {
        document.body.addEventListener('click', (event) => {
            const opener = event?.target?.closest('[data-zoom-modal]');
            if (!opener) {
                return;
            }

            event?.preventDefault();

            const data = {
                image_url: opener?.dataset?.zoomModal || null,
                breakpoint_max: opener?.dataset?.zoomModalBreakpointMax || null
            };

            openZoomModal(data);
        });
    }

    function setUpClose() {
        document.body.addEventListener('click', (event) => {
            if (event?.target?.closest('[data-modal-zoom-close]')) {
                event?.preventDefault();

                closeZoomModal();
            }
        });

        document?.addEventListener('keydown', (event) => {
            if (event.key !== "Escape" && event.key !== "Esc") {
                return;
            }

            closeZoomModal();
        });
    }

    setUpZoomListeners();
    setUpOpeners();
    setUpClose();
}

document.addEventListener('DOMContentLoaded', () => {
    initZoomModals();
});
