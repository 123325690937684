import Bugsnag, { PREFIX } from '@/helpers/bugsnag';

const selectors = {
    video: '[data-video]'
};

const classes = {
    loaded: 'video--loaded'
};

let userInteraction = false;
async function loadOnceOnUserInteraction(cb) {
    if (!!userInteraction) {
        return;
    }

    await cb();
    userInteraction = true;
}

const registerCallbackOnUserInteraction = (asyncLoad) => {
    const events = ['touchstart', 'mouseover', 'keydown'];
    if (!events) {
        return;
    }

    if (!events?.length) {
        return;
    }

    events?.forEach((event) => {
        document.addEventListener(event, async () => {
            await loadOnceOnUserInteraction(asyncLoad);
        }, {
            once: true
        });
    });
};

document.addEventListener("DOMContentLoaded", () => {
    const videos = document.querySelectorAll(selectors?.video);
    if (!videos) {
        return;
    }

    if (!videos?.length) {
        return;
    }

    registerCallbackOnUserInteraction(async () => {
        try {
            const { default: lozad } = await import("lozad");
            if (!lozad) {
                return;
            }

            const observer = lozad(selectors?.video, {
                loaded(video) {
                    const autoplay = video?.dataset?.autoplay;

                    video?.classList?.add(classes?.loaded);

                    if (typeof autoplay === "undefined") {
                        return;
                    }

                    video?.setAttribute("autoplay", true);
                    video?.play();
                }
            });

            if (!observer) {
                return;
            }

            observer?.observe();
        } catch (error) {
            try {
                Bugsnag.notify(new Error(`[${PREFIX}] Unable to load video`), (event) => {
                    event.severity = 'error';

                    event.addMetadata('parsedError', {
                        response: error?.data || error?.response,
                        error,
                    });
                });
            } catch (error) {}
        }
    });
});
