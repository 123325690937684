const selectors = {
    recommendedOption: '[data-recommended-option-name]',
    recommendedLengthBadge: '[data-recommended-option-name="length"]',
    recommendedWandSizeBadge: '[data-recommended-option-name="size"]',
};

class RecommendedBadges {
    constructor() {
        this.initAiResults();
        this.initProductBadges();
    }

    initAiResults() {
        this.aiResults = JSON.parse(localStorage.getItem('ai-tool-results') || '{}');
        document.addEventListener('ai-tool:results', this.onAiResultsChange.bind(this));
    }

    onAiResultsChange(event) {
        const results = event?.detail?.resultsFinal;
        if (!results) {
            return;
        }

        this.aiResults = results;
        this.initProductBadges();
    }

    initProductBadges() {
        if (!Object.keys(this.aiResults || {})?.length) {
            return;
        }

        this.initLengthBadges();
        this.initWandSizeBadges();
    }

    initLengthBadges() {
        const badges = document.querySelectorAll(selectors.recommendedLengthBadge);
        if (!badges?.length) {
            return;
        }
        
        const swiper = badges[0].closest('.swiper');
        if (swiper) {
            swiper.classList.remove('swiper--with-recommended-badge');
        }

        badges.forEach(badge => { badge.style.display = 'none'; });

        const {minRecommended, maxRecommended, averageLengthRecommended} = this.aiResults;
        if (typeof minRecommended !== 'number' || typeof maxRecommended !== 'number') {
            return;
        }

        const avgMM = (typeof averageLengthRecommended === 'number') ? averageLengthRecommended : ((minRecommended + maxRecommended) / 2);
 
        const recommendedLength = `${avgMM}mm`;

        const recommendedLengthBadge = document.querySelector(`${ selectors.recommendedLengthBadge }[data-recommended-option-value="${ recommendedLength }"]`);
        if (!recommendedLengthBadge) {
            return;
        }

        if (swiper) {
            swiper.classList.add('swiper--with-recommended-badge');
        }

        const slide = recommendedLengthBadge.closest('.swiper-slide');
        if (slide) {
            slide.style.order = '-1';
        }

        recommendedLengthBadge.style.display = 'flex'; 
    }

    initWandSizeBadges() {
        const badges = document.querySelectorAll(selectors.recommendedWandSizeBadge);
        if (!badges?.length) {
            return;
        }
        
        const swiper = badges[0].closest('.swiper');
        if (swiper) {
            swiper.classList.remove('swiper--with-recommended-badge');
        }

        badges.forEach(badge => { badge.style.display = 'none'; });

        const {wandType} = this.aiResults;
        if (typeof wandType !== 'string') {
            return;
        }

        const recommendedWandSizeBadge = document.querySelector(`${ selectors.recommendedWandSizeBadge }[data-recommended-option-value="${ wandType }"]`);
        if (!recommendedWandSizeBadge) {
            return;
        }

        if (swiper) {
            swiper.classList.add('swiper--with-recommended-badge');
        }

        const slide = recommendedWandSizeBadge.closest('.swiper-slide');
        if (slide) {
            slide.style.order = '-1';
        }

        recommendedWandSizeBadge.style.display = 'flex'; 
    }
}

if (document.querySelector(selectors.recommendedOption)) {
    new RecommendedBadges();
}
