const selectors = {
    dropdown: '[data-custom-dropdown]'
};

async function customDropdownInit() {
    const dropdownElements = document.querySelectorAll(selectors?.dropdown);
    if (!dropdownElements) {
        return;
    }

    if (!dropdownElements?.length) {
        return;
    }

    const { default: Choices } = await import("choices.js");

    dropdownElements?.forEach((dropdown) => {
        new Choices(dropdown, {
            searchEnabled: false,
            itemSelectText: '',
            placeholder: true,
            shouldSort: false,
            position: 'bottom'
        });
    });
}

document.addEventListener('DOMContentLoaded', async () => {
    await customDropdownInit();
});
