import * as focusTrap from 'focus-trap'; 
import {
    enableBodyScroll,
    disableBodyScroll
} from '@/helpers/bodyScrollLockWrapper';

const drawersInit = () => {
    const drawerToggles = document.querySelectorAll('[data-drawer-toggle]');
    if (!drawerToggles?.length) {
        return;
    }

    const drawers = document.querySelectorAll('[data-drawer]');
    if (!drawers?.length) {
        return;
    }

    drawers.forEach((drawer) => {
        const teleport = drawer?.dataset?.drawerTeleport === 'true';
        if (teleport) {
            document.body.appendChild(drawer);
        }
        
        const drawer_id = drawer?.dataset?.drawer;
        if (!drawer_id) {
            return;
        }

        const toggleBtns = document.querySelectorAll(`[data-drawer-toggle="${ drawer_id }"]`);
        if (!toggleBtns?.length) {
            return;
        }

        const closeBtns = drawer.querySelectorAll('[data-drawer-close]');

        const trap = focusTrap?.createFocusTrap(drawer);

        function openDrawer() {
            toggleBtns?.forEach((button) => {
                button?.setAttribute('aria-expanded', "true");
            });

            if (closeBtns?.length) {
                closeBtns?.forEach((button) => {
                    button?.setAttribute('aria-expanded', "true");
                });
            }

            drawer?.classList?.add('drawer-new--opened');

            trap?.activate();

            const container = drawer.querySelector('[data-drawer-container]');

            disableBodyScroll(container ?? drawer, {
                allowTouchMove(el) {
                    while (el && el !== document?.body) {
                        if (el.getAttribute('data-allow-scroll') !== null) {
                            return true;
                        }
    
                        el = el?.parentElement;
                    }

                    return false;
                }
            });
        }

        function closeDrawer() {
            toggleBtns?.forEach((button) => {
                button?.setAttribute('aria-expanded', "false");
            });

            if (closeBtns?.length) {
                closeBtns?.forEach((button) => {
                    button?.setAttribute('aria-expanded', "false");
                });
            }

            trap?.deactivate();

            drawer?.classList?.remove('drawer-new--opened');

            const container = drawer?.querySelector('[data-drawer-container]');

            enableBodyScroll(container ?? drawer);
        }

        function initDrawerToggleBtns() {
            toggleBtns?.forEach((button) => {
                button?.addEventListener('click', (event) => {
                    event?.preventDefault();

                    openDrawer();
                });
            });
        }

        function initDrawerClose() {
            drawer?.addEventListener('click', (event) => {
                const drawerContainer = event?.target?.closest('[data-drawer-container]');
                const drawerCloseButton = event?.target?.closest('[data-drawer-close]');

                if (drawerContainer && !drawerCloseButton) {
                    return;
                }

                closeDrawer();
            });

            document.addEventListener('keydown', (event) => {
                if (event?.key === 'Escape') {
                    closeDrawer();
                }
            });

            document.addEventListener('drawers:close', () => {
                closeDrawer();
            });
        }

        initDrawerToggleBtns();
        initDrawerClose();
    });
};

document.addEventListener('DOMContentLoaded', () => {
    drawersInit();
});
