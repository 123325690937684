function getURLParameter(name) {
    return decodeURIComponent((new RegExp(`[?|&]${name}=([^&;]+?)(&|#|;|$)`).exec(window.location.search) || [null, ''])[1].replace(/\+/g, '%20')) || null;
}

async function utm() {
    if (window?.location?.href?.indexOf('utm_source') === -1) {
        return;
    }

    const { default: Cookies } = await import('js-cookie');

    const cookieUtm = Cookies.get('utm');
    const currentUtm = getURLParameter('utm_source');

    if (cookieUtm && cookieUtm === currentUtm) {
        return;
    }

    Cookies.set('utm', getURLParameter('utm_source'));
}

document.addEventListener('DOMContentLoaded', async () => {
    await utm();
});
