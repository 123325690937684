export const ATTRIBUTE_SECTION_ID = 'data-section-id';
export const ATTRIBUTE_SECTION_TYPE = 'data-section-type';
export const ATTRIBUTE_SECTION_INITIALIZED = 'data-section-initialized';

export function validateContainerElement(container) {
    if (!(container instanceof Element)) {
        throw new TypeError(
            'Theme Sections: Attempted to load section. The section container provided is not a DOM element.'
        );
    }

    if (!container?.getAttribute(ATTRIBUTE_SECTION_ID)) {
        throw new Error(
            `Theme Sections: The section container provided does not have an id assigned to the ${ 
            ATTRIBUTE_SECTION_ID 
            } attribute.`
        );
    }

    return container;
}

class Section {
    id = null;

    type = null;

    container = null;

    _extensions = [];

    constructor(container) {
        this.container = validateContainerElement(container);
        this.id = container?.getAttribute(ATTRIBUTE_SECTION_ID);
        this.type = container?.getAttribute(ATTRIBUTE_SECTION_TYPE);

        this.initExtensions();
    }

    async load() {
        await this.onLoad();

        return this;
    }

    async onLoad() {
        const extensions = this._extensions;
        if (!extensions) {
            return;
        }

        if (!extensions?.length) {
            return;
        }

        for (const extension of extensions) {
            await extension?.onLoad();
        }
    }

    async onUnload() {
        const extensions = this._extensions;
        if (!extensions) {
            return;
        }

        if (!extensions?.length) {
            return;
        }

        for (const extension of extensions) {
            await extension?.onUnload();
        }
    }

    async onSelect() {
        const extensions = this._extensions;
        if (!extensions) {
            return;
        }

        if (!extensions?.length) {
            return;
        }

        for (const extension of extensions) {
            await extension?.onSelect();
        }
    }

    async onDeselect() {
        const extensions = this._extensions;
        if (!extensions) {
            return;
        }

        if (!extensions?.length) {
            return;
        }

        for (const extension of extensions) {
            await extension?.onDeselect();
        }
    }

    async onBlockSelect() {
        const extensions = this._extensions;
        if (!extensions) {
            return;
        }

        if (!extensions?.length) {
            return;
        }

        for (const extension of extensions) {
            await extension?.onBlockSelect();
        }
    }

    async onBlockDeselect() {
        const extensions = this._extensions;
        if (!extensions) {
            return;
        }

        if (!extensions?.length) {
            return;
        }

        for (const extension of extensions) {
            await extension?.onDeselect();
        }
    }

    initExtensions() {
        const container = this.container;
        if (!container) {
            return;
        }

        const type = this.type;
        if (!type) {
            return;
        }

        const id = this.id;
        if (!id) {
            return;
        }

        const extensions = this.extensions();
        if (!extensions) {
            return;
        }

        if (!extensions?.length) {
            return;
        }

        extensions?.forEach((ExtensionClass) => {
            this._extensions = [
                ...(this._extensions || []),
                new ExtensionClass({
                    id,
                    type,
                    container,
                })
            ];
        });
    }

    extensions() {
        return [];
    }

    extend(extension) {
        Object.assign(this, extension);
    }
}

export default Section;
