import serialize from "form-serialize";
import { EVENT_MINICART_PRODUCT_ADD } from "@/store/events";

function productSubmitEvent(button) {
    const loading = !!button?.classList?.contains('loading');
    if (loading) {
        return;
    }

    button?.classList?.add('loading');

    const form = button?.form;
    if (!form) {
        return;
    }

    const data = serialize(form, {
        hash: true,
        disabled: true,
        empty: false
    });

    if (data?.selling_plan) {
        data.selling_plan = parseInt(data?.selling_plan) || null;
    }

    if (!data) {
        return;
    }

    button.disabled = true;
    button?.classList?.add("disabled");

    // eslint-disable-next-line no-undef
    document.dispatchEvent(new CustomEvent(EVENT_MINICART_PRODUCT_ADD, {
        detail: {
            items: [
                data
            ],
            callback() {
                button?.classList?.remove('loading');

                setTimeout(() => {
                    button?.classList?.remove('loading');

                    button.disabled = false;
                    button?.classList?.remove("disabled");
                }, 2000);

                (() => {
                    const quickviewModalClose = document.querySelector('[data-modal="quickview-modal"] [data-modal-close]');
                    if (!quickviewModalClose) {
                        return;
                    }

                    quickviewModalClose?.click();
                })();
            },
            errorCallback() {
                button.disabled = false;

                button?.classList?.remove("disabled");
            }
        }
    }));
}

function initAddToCart() {
    document.body.addEventListener('click', async (event) => {
        const button = event?.target?.closest('[data-product-submit]');
        if (!button) {
            return;
        }

        event?.preventDefault();
        event?.stopPropagation();
        event?.stopImmediatePropagation();

        await productSubmitEvent(button);
    });
}

document.addEventListener('DOMContentLoaded', () => {
    initAddToCart();
});
