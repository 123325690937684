import Bugsnag, { PREFIX } from '@/helpers/bugsnag';

function initKlaviyoForms() {
    const forms = document.querySelectorAll('form[action="//manage.kmail-lists.com/subscriptions/subscribe"]');
    if (!forms) {
        return;
    }

    if (!forms?.length) {
        return;
    }

    forms?.forEach((form) => {
        const successEl = form.querySelector('.success_message');
        const errorEl = form.querySelector('.error_message');

        form?.addEventListener('submit', async (event) => {
            event?.preventDefault();

            if (!!errorEl) {
                errorEl.innerHTML = 'Something went wrong. Please try again.';
            }

            const list_id = form.querySelector('input[name="g"]').value;
            if (!list_id) {
                if (!errorEl) {
                    return;
                }

                errorEl.style.display = 'block';

                return;
            }

            const inputEmail = form.querySelector('input[name="email"]')?.value;
            const inputName = form.querySelector('input[name="$first_name"]')?.value;

            const formData = new FormData(form);

            try {
                const response = await fetch('https://a.klaviyo.com/client/subscriptions/?company_id=NupmWj', {
                    method: 'POST', 
                    headers: {
                        revision: '2024-02-15',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        data: {
                            type: 'subscription',
                            attributes: {
                                custom_source: window.location.href,
                                profile: {
                                    data: {
                                        type: 'profile',
                                        attributes: {
                                            ...(inputEmail ? {
                                                email: inputEmail
                                            } : {}),
                                            ...(inputName ? {
                                                first_name: inputName
                                            } : {})
                                        }
                                    }
                                }
                            },
                            relationships: {
                                list: {
                                    data: {
                                        type: 'list', 
                                        id: list_id
                                    }
                                }
                            }
                        }
                    })
                });

                if (!response) {
                    if (!errorEl) {
                        return;
                    }

                    errorEl.style.display = 'block';

                    return;
                }

                if (!response?.success && !!response?.errors?.length) {
                    if (!errorEl) {
                        return;
                    }

                    errorEl.innerHTML = response?.errors?.join(', ') || "";
                    errorEl.style.display = 'block';
                    return;
                }

                if (!successEl) {
                    return;
                }

                successEl.style.display = 'block';
            } catch (error) {
                try {
                    Bugsnag.notify(new Error(`[${PREFIX}] Unable to subscribe to Klaviyo`), (event) => {
                        event.severity = 'error';

                        event.addMetadata('parsedError', {
                            url: `https://manage.kmail-lists.com/ajax/subscriptions/subscribe`,
                            method: "POST",
                            body: formData,
                            error,
                            response: error?.data || error?.response,
                        });
                    });
                } catch (error) {}

                if (!errorEl) {
                    return;
                }

                errorEl.style.display = 'block';
            }
        });

        const inputs = form?.querySelectorAll('input');
        if (!inputs) {
            return;
        }

        if (!inputs?.length) {
            return;
        }

        inputs?.forEach((input) => {
            input?.addEventListener('input', () => {
                if (errorEl) {
                    errorEl.style.display = 'none';
                }

                if (successEl) {
                    successEl.style.display = 'none';
                }
            });
        });
    });
}

document.addEventListener('DOMContentLoaded', () => {
    initKlaviyoForms();
});
