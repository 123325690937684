import redaxios from 'axios';

export const BASE_URL = import.meta.env.FERA_BASE_URL || 'https://api.fera.ai/v3/public';
export const SUBMISSION_URL = import.meta.env.FERA_SUBMISSION_URL || 'https://api.fera.ai/v3/public';
export const PUBLIC_KEY = import.meta.env.FERA_PUBLIC_KEY || 'pk_8729959a2af8cc0a8bc7041c6b7fc469b4423a713a35b6fa39f5d68bae6a9583';

export const SORT_QUALITY = 'quality:desc';
export const SORT_MEDIA = 'media:desc';
export const SORT_HIGHEST_RATING = 'rating:desc';
export const SORT_LOWEST_RATING = 'rating:asc';
export const SORT_MOST_RECENT = 'created_at:desc';
export const SORT_OLDEST = 'created_at:asc';

const client = redaxios.create({
    baseURL: BASE_URL,
    headers: {
        'Content-Type': 'application/json',
        'Accept': '*/*',
        'Accept-Encoding': 'gzip, deflate, br',
    },
    params: {
        api_key: PUBLIC_KEY,
        client: 'fjs-3.2.0',
    },
});

export const listReviews = (params = {}) => {
    return client.get('/reviews.json', {
        params,
    });
};

export const getReview = (id) => {
    return client.get(`/reviews/${id}.json`);
};

export const getMedias = (params = {}) => {
    return client.get(`/media.json`, {
        params,
    });
};

export const getMedia = (id) => {
    return client.get(`/media/${id}.json`);
};

export const getAllProductsAggregateRating = (params = {}) => {
    return client.get('/products/ratings.json', {
        params,
    });
};

export const getProductAggregateRating = (id) => {
    return client.get(`/products/${id}/rating.json`);
};

export const getStoreRating = (params = {}) => {
    return client.get('/store/rating.json', {
        params,
    });
};

export const getAllProductReviews = (params = {}) => {
    return client.get('/products/reviews.json', {
        params,
    });
};

export const getAllProductsMedias = (params = {}) => {
    return client.get('/products/media.json', {
        params,
    });
};

export const getProductReviews = (id, params = {
    sort: SORT_QUALITY,
    page: 1,
    per_page: 6,
    include_aggregate_rating: true,
    offset: 0,
    limit: 6,
    include_product: true,
}) => {
    return client.get(`/products/${id}/reviews`, {
        params,
    });
};

export const getProductMedias = (id, {
    page = 1,
    page_size = 6,
    offset = 0,
    type = 'photo',
} = {
    page: 1,
    page_size: 6,
    offset: 0,
    type: 'photo',
}) => {
    return client.get(`/products/${id}/media.json`, {
        params: {
            page,
            page_size,
            offset,
            type,
        },
    });
};

export const getStoreSettings = () => {
    return client.get('/store/settings.json');
};

export const getAllStoreReviews = (params = {}) => {
    return client.get('/store/reviews.json', {
        params,
    });
};

export const getAllStoreMedias = (params = {}) => {
    return client.get('/store/media.json', {
        params,
    });
};

export const createSubmission = ({
    visitor_id
}) => {
    return client.post(`${SUBMISSION_URL}/submissions.json`, {
        visitor_id,
    });
};

export const createReview = ({
    token,
    body = '',
    heading = '',
    media = [],
    product_id = '',
    rating = 5,
} = {
    id: '',
    body: '',
    heading: '',
    media: [],
    product_id: '',
    rating: 5,
}) => {
    return client.post(`${SUBMISSION_URL}/submissions/${token}/reviews.json`, {
        body,
        heading,
        media,
        product_id,
        rating,
    });
};

export const setSubmissionCustomer = ({
    token,
    display_name,
    email,
    full_name,
    visitor_id,
} = {
    token: '',
    display_name: '',
    email: '',
    full_name: '',
    visitor_id: '',
}) => {
    return client.post(`${SUBMISSION_URL}/submissions/${token}/customers.json`, {
        display_name,
        email,
        full_name,
        visitor_id,
    });
};

export const finalizeSubmission = ({
    token,
}) => {
    return client.post(`${SUBMISSION_URL}/submissions/${token}/finalize.json`);
};

export const deleteSubmission = ({
    id,
}) => {
    return client.delete(`${SUBMISSION_URL}/submissions/${id}.json`);
};

export default {
    listReviews,
    getReview,
    getMedias,
    getMedia,
    getAllProductsAggregateRating,
    getProductAggregateRating,
    getStoreRating,
    getAllProductReviews,
    getAllProductsMedias,
    getProductReviews,
    getProductMedias,
    getStoreSettings,
    getAllStoreReviews,
    getAllStoreMedias,

    createSubmission,
    createReview,
    setSubmissionCustomer,
    finalizeSubmission,
    deleteSubmission,
};
