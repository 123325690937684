import axios from "axios";
import {
    debouncedClearAllBodyScrollLocks,
    debouncedDisableBodyScroll,
} from "@/helpers/bodyScrollLockWrapper";

const selectors = {
    trigger: '[data-details-modal]',
    modal: '[data-modal]',
    close: '[data-modal-close]',
};

const classes = {
    opened: 'account-dashboard-modal--opened',
};

async function open(handle) {
    if (!handle) {
        return;
    }

    const html = await axios.get(`/products/${handle}`, {
        params: {
            view: 'modal-details'
        }
    })?.then((response) => {
        return response?.data;
    });

    if (!html) {
        return;
    }

    const modal = document.createElement('div');

    modal.innerHTML = html || '';

    document.body.appendChild(modal);

    const body = modal.querySelector(`[data-modal-body]`);
    if (!body) {
        return;
    }

    debouncedDisableBodyScroll(body);
}

async function triggerHandler(event) {
    const target = event?.target?.closest(selectors?.trigger);
    if (!target) {
        return;
    }

    const handle = target?.dataset?.productHandle;
    if (!handle) {
        return;
    }

    await open(handle);
}

function closeHandler(event) {
    const target = event?.target?.closest(selectors?.close);
    if (!target) {
        return;
    }

    const modal = target?.closest(selectors?.modal);
    if (!modal) {
        return;
    }

    modal?.classList?.remove(classes?.opened);

    debouncedClearAllBodyScrollLocks();

    setTimeout(() => {
        modal?.remove();
    }, 1000);
}

function initClose() {
    document.body.addEventListener('click', closeHandler);
}

function initTriggers() {
    document.body.addEventListener('click', triggerHandler);

    const triggers = document.querySelectorAll(selectors?.trigger);
    if (!triggers) {
        return;
    }

    if (!triggers?.length) {
        return;
    }

    triggers?.forEach((trigger) => {
        trigger?.addEventListener('click', async (event) => {
            event?.preventDefault();
            event?.stopPropagation();
            event?.stopImmediatePropagation();

            await triggerHandler(event);
        });
    });
}

document.addEventListener('DOMContentLoaded', () => {
    initTriggers();
    initClose();
});
