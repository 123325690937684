const waitNetwork = async (callback = () => {}, ...args) => {
    return new Promise((resolve) => {
        window.addEventListener('online', async () => {
            if (typeof callback !== "function") {
                return;
            }

            resolve(await callback(...args));
        });
    });
};

export default function importSection(sectionType) {
    try {
        return import(`./sections/${sectionType}.js`);
    } catch (error) {
        return waitNetwork(importSection, sectionType);
    }
}
